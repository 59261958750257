import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        currentNav:'front',
        username:'',
        oldpassword:'',
        loggedInUser:{
            id:0,
            name:'',
        },
        securityAppName:'MobileIDAdmin',
        loggedIn:false,
        token:null,
        didFailAuth:false,

        badgeData:{},
        logoOverride:'',
        configOverride:'',
        locations:[],
        departments:[],
        alertTemplateList:[],
        personRoleList:[],
        messageTemplateList:[],
        
        showHeader:true,


    },
    mutations:{
        updateField,
        failAuth(state){
            state.loggedIn=false;
            state.didFailAuth=true;
        },
    },
    getters:{
        getField,
    },
    actions:{
        failedAuthLogout(context){
            context.commit('failAuth'); 
        },
    }
})